<template>
  <div>
    <div class="page" v-if="ispc">
    <div class="block">
      <el-carousel height="530px">
        <el-carousel-item v-for="(item, index) in imgList" :key="index">
          <div>
            <img :src="item.src" style="width: 100%;height: 530px;">
          <div style="font-size: 30px;position: relative;top: -250px;color: #fff;padding-left: 15%;width: 600px;line-height: 40px;">
            {{ item.text }}
          </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div style="display: flex;justify-content: center;background-color: #409EFF;">
      <div style="border-right: 1px solid #fff;border-left: 1px solid #fff;">
        <el-button type="primary">
          <div style="padding: 10px;">
            <div>
              售前咨询
            </div>
            <div style="margin-top: 10px;">
              为您提供一对一服务
            </div>
          </div>
        </el-button>
      </div>
      <div style="border-right: 1px solid #fff;">
        <el-button type="primary">
          <div style="padding: 10px;">
            <div>
              产品体验
            </div>
            <div style="margin-top: 10px;">
              为您提供一对一服务
            </div>
          </div>
        </el-button>
      </div>
      <div style="border-right: 1px solid #fff;">
        <el-button type="primary">
          <div style="padding: 10px;">
            <div>
              运营咨询
            </div>
            <div style="margin-top: 10px;">
              为您提供一对一服务
            </div>
          </div>
        </el-button>
      </div>
      <div style="border-right: 1px solid #fff;">
        <el-button type="primary">
          <div style="padding: 10px;">
            <div>
              个性化定制
            </div>
            <div style="margin-top: 10px;">
              为您提供一对一服务
            </div>
          </div>
        </el-button>
      </div>
    </div>
    <div class="middle">
      <!-- <div style="width: 20%;"></div> -->
      <div style="width:100%;">
        <div class="mida1" style="background-color: #fff;display: flex;padding: 20px 10px;">
          <div style="width: 10%;"></div>
          <div style="margin: 20px 0px;width:80%;">
            <div class="centera1">
              <div class="centertexttit">
                <div class="centertext">
                  致力于互联网软件研发服务
                </div>
                <div style="font-size: 15px;color: #000;margin-top: 20px;line-height: 35px;">
                  北京酷演科技有限公司（众聚生活）总部位于首都北京，专注于商城研发、微信开发、系统软件研发、原生态APP开发、B2B2C、O2O等互联网平台，以及新兴技术、大数据和云计算。众聚生活有一只执行力强大的团队，我们从一开始就注定是一家客户至上，服务为基，技术为本的科技公司。
众聚生活将全面提升品牌影响力、提高技术、完善服务。助力中国互联网共同成长，为实现中华民族的伟大复兴梦尽自己的绵薄之力！公司具备多年的互联网项目开发经验，优秀的作品就是我们的品牌，相信品牌的力量！
我们的客户来自各行各业，为了共同目标，我们在工作上密切配合，从创业型小企业到知名品牌，感谢他们对我们的高要求，感谢他们从不同领域给我们带来的挑战，让我们激情的团队有机会用头脑与智慧不断的给客户带来惊喜。
从PC端到移动终端，从互联网品牌建设到微信营销，从创意策划到系统平台开发，众聚生活都积累了丰富经验，并已为众企业、公司及企事业集团提供激动人心的专业服务！
                </div>
                <div class="morebut">
                  <el-button plain>MORE</el-button>
                </div>
              </div>
              <div class="imghuolie">
                <img src="http://official.kukuyan.cn/ky/profile/upload/2024/10/14/微信图片_20241014171813_20241014171857A019.png" class="imghu">
                <!-- <img src="https://www.hlnwl.com/webimages/iyear.png" class="imghu"> -->
              </div>
            </div>
          </div>
          <div style="width: 10%;"></div>
        </div>
        <!-- 主营业务 -->
        <div class="mida1" style="background-color: #fff;display: flex;padding: 20px 10px;">
          <div style="width: 10%;"></div>
          <div style="margin: 20px 0px;width:80%;">
            <div class="text1" style="font-size: 30px;font-weight: bold;color: #000;">
              主营业务
            </div>
            <div class="text1" style="font-size: 20px;color: #909399;margin-top: 20px;">
              多年APP、软件开发与行业经验的沉淀，覆盖数十种主流行业，对焦主流、热门的行业。
            </div>
            <div class="text1" style="font-size: 20px;color: #909399;margin-top: 10px;">
              全国2000家企业客户、1000万用户正在使用，专业的开发团队，只为制作出让客户满意，经得起考验的产品
            </div>
            <div class="griddiv">
              <div class="grid-containerd">
                <div v-for="(item, index) in gridItems" :key="index" class="grid-itemd">
                  <div class="gridDiv">
                    <div>
                      <img :src="item.src" class="iconimg" />
                    </div>
                    <div style="margin-top: 20px;display: flex;justify-content: center;align-items: center;">
                      {{ item.title }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 10%;"></div>
        </div>
        <!-- 经典案例 -->
        <div class="mida1" style="background-color: #fff;display: flex;padding: 20px 10px;">
          <div style="width: 10%;"></div>
          <div style="margin: 20px 0px;width:80%;">
            <div class="casetext">
              经典案例
            </div>

            <div class="griddiv">
              <div class="grid-containerca">
                <div v-for="(item, index) in caseList" :key="index" class="grid-itemca">
                  <div class="gridDiv">
                    <div>
                      <img :src="item.src" class="caseimg" />
                    </div>
                    <div class="casetit">
                      {{ item.title }}
                    </div>
                    <div class="caselab">
                      {{ item.label }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="morebut">
              <el-button plain>MORE</el-button>
            </div>
          </div>
          <div style="width: 10%;"></div>
        </div>
        <!-- 统计 -->
        <div class="mida1"
          style="background-image: url('https://www.hlnwl.com/webimages/inumbg.jpg');display: flex; background-repeat: no-repeat; margin-left: auto; margin-right: auto; max-width: 1920px">
          <div style="width: 10%;"></div>
          <div style="margin: 20px 0px;width:80%;">
            <div class="tjdiv">
              <div class="tjdiv1">
                <div>
                  <div class="tjdiv1tit">
                    9
                  </div>
                  <div class="tjdiv2tit" style="margin-top: 10px;">
                    9年互联网软件研发经验
                  </div>
                </div>
                <div>
                  <div class="tjdiv1tit">
                   20
                  </div>
                  <div class="tjdiv2tit" style="margin-top: 10px;">
                    500强企业我们已经服务20家
                  </div>
                </div>
                <div>
                  <div class="tjdiv1tit">
                    76
                  </div>
                  <div class="tjdiv2tit" style="margin-top: 10px;">
                    客户中76个品牌为行业领袖
                  </div>
                </div>
              </div>
              <div class="tjdiv2">
                <div>
                  <div class="tjdiv1tit">
                    3200
                  </div>
                  <div class="tjdiv2tit" style="margin-top: 10px;">
                    服务品牌总市值超过3200万
                  </div>
                </div>
                <div>
                  <div class="tjdiv1tit">
                    1000
                  </div>
                  <div class="tjdiv2tit" style="margin-top: 10px;">
                    1000家客户案例实施成果
                  </div>
                </div>
                <div>
                  <div class="tjdiv1tit">
                    6
                  </div>
                  <div class="tjdiv2tit" style="margin-top: 10px;">
                    6余项互联网研发领域软件著作权证书
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 10%;"></div>
        </div>
        <!-- 咨询 -->
        <div class="mida1" style="background-color: #fff;display: flex;padding: 20px 10px;">
          <div style="width: 10%;"></div>
          <div style="margin: 20px 0px;width:80%;">
            <div class="casetext">
              众聚资讯
            </div>

            <div style="margin-top: 10px">
              <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">行业新闻</el-breadcrumb-item>
                <el-breadcrumb-item><a href="/">APP新闻</a></el-breadcrumb-item>
                <el-breadcrumb-item>微信新闻</el-breadcrumb-item>
                <el-breadcrumb-item>解决方案</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
            <div class="newdiv">
              <div class="newdiv1">
                <div style="padding: 10px;background-color: #f3f4f6;">
                  <div class="newdiv1tit">
                    【APP定制开发】直播app定制开发所需功能介绍！_河南众聚生活智能
                  </div>
                  <div style="margin: 10px 0px;">
                    2021-12-15
                  </div>
                  <div>
                    <img src="http://api.hlnwl.com/uploads/20220217/4122a22cdae5cc079911de31aa762dab.jpg"
                      class="caseimg" />
                  </div>
                </div>
              </div>
              <div class="newdiv1">
                <div style="padding: 10px;background-color: #f3f4f6;height: calc(50% - 25px);">
                  <div class="newdiv1tit" style="border-bottom: solid 1px rgb(226 229 235);height: 70%;">
                    开发小程序多少钱
                  </div>
                  <div style="margin: 10px 0px;display: flex;justify-content: space-between;">
                    <div>
                      2021-12-15
                    </div>
                    <div>
                      <img src="https://www.hlnwl.com/webimages/inewarr.png" />
                    </div>
                  </div>
                </div>
                <div style="padding: 10px;background-color: #f3f4f6;margin-top: 10px;height: calc(50% - 25px);">
                  <div class="newdiv1tit" style="border-bottom: solid 1px rgb(226 229 235);height: 70%;">
                    开发小程序多少钱
                  </div>
                  <div style="margin: 10px 0px;display: flex;justify-content: space-between;">
                    <div>
                      2021-12-15
                    </div>
                    <div>
                      <img src="https://www.hlnwl.com/webimages/inewarr.png" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="newdiv1">
                <div style="padding: 10px;background-color: #f3f4f6;height: calc(50% - 25px);">
                  <div class="newdiv1tit" style="border-bottom: solid 1px rgb(226 229 235);height: 70%;">
                    开发小程序多少钱
                  </div>
                  <div style="margin: 10px 0px;display: flex;justify-content: space-between;">
                    <div>
                      2021-12-15
                    </div>
                    <div>
                      <img src="https://www.hlnwl.com/webimages/inewarr.png"/>
                    </div>
                  </div>
                </div>
                <div style="padding: 10px;background-color: #f3f4f6;margin-top: 10px;height: calc(50% - 25px);">
                  <div class="newdiv1tit" style="border-bottom: solid 1px rgb(226 229 235);height: 70%;">
                    开发小程序多少钱
                  </div>
                  <div style="margin: 10px 0px;display: flex;justify-content: space-between;">
                    <div>
                      2021-12-15
                    </div>
                    <div>
                      <img src="https://www.hlnwl.com/webimages/inewarr.png"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="morebut">
              <el-button plain>MORE</el-button>
            </div>
          </div>
          <div style="width: 10%;"></div>
        </div>

        <!-- <div class="mida1" style="background-color: #fff;display: flex;padding: 20px 10px;">
          <div style="width: 10%;"></div>
          <div style="width:80%;">
            <div class="text1" style="font-size: 30px;font-weight: bold;color: #000;">
              众聚生活APP开发
            </div>
            <div>
              <div style="display: flex;justify-content: space-around;align-items: center;margin-top: 20px;">
                <div>
                  <div>
                    <img src="https://www.jinyouapp.com/static/a/web/images/pic11.png" class="iconimg" />
                  </div>
                  <div class="icontext">
                    思维导图
                  </div>
                </div>
                <div>
                  <div>
                    <img src="https://www.jinyouapp.com/static/a/web/images/pic12.png" class="iconimg" />
                  </div>
                  <div class="icontext">
                    思维导图
                  </div>
                </div>
                <div>
                  <div>
                    <img src="/image/ic.jpg" class="iconimg" />
                  </div>
                  <div class="icontext">
                    思维导图
                  </div>
                </div>
                <div>
                  <div>
                    <img src="/image/ic.jpg" class="iconimg" />
                  </div>
                  <div class="icontext">
                    思维导图
                  </div>
                </div>
                <div>
                  <div>
                    <img src="/image/ic.jpg" class="iconimg" />
                  </div>
                  <div class="icontext">
                    思维导图
                  </div>
                </div>
              </div>
              <div style="display: flex;justify-content: center;align-items: center;margin: 40px 0px;">
                <el-button type="primary" round>咨询定制服务</el-button>
              </div>
            </div>
          </div>
          <div style="width: 10%;"></div>
        </div> -->
        <!-- <div class="mida1" style="display: flex;padding: 20px 10px;">
          <div style="width: 20%;"></div>
          <div style="width:60%;padding: 20px 10px;">
            <div class="text1" style="font-size: 30px;font-weight: bold;color: #000;">
              专注O2O领域技术开发，提供互联网整体解决方案
            </div>
            <div class="text1" style="font-size: 20px;color: #302f2f;margin-top: 20px;">
              我们针对企业的个性化需求，结合各行业实践经验，为客户提供了成熟的行业解决方案。
            </div>
            <div class="text1" style="font-size: 13px;color: #909399;margin-top: 10px;">
              众聚生活始终以客户为中心，不断加大在新技术上的研发投入。众聚生活会和大家一起努力，更好承担社会责任，更多创造社会价值。
            </div>
            <div>
              <div style="display: flex;justify-content: space-around;align-items: center;margin-top: 20px;">
                <div>
                  <div>
                    <img src="https://www.jinyouapp.com/static/a/web/images/icon31.png" class="iconimg1" />
                  </div>
                  <div class="icontext">
                    思维导图
                  </div>
                </div>
                <div>
                  <div>
                    <img src="https://www.jinyouapp.com/static/a/web/images/icon32.png" class="iconimg1" />
                  </div>
                  <div class="icontext">
                    思维导图
                  </div>
                </div>
                <div>
                  <div>
                    <img src="https://www.jinyouapp.com/static/a/web/images/icon32.png" class="iconimg1" />
                  </div>
                  <div class="icontext">
                    思维导图
                  </div>
                </div>
                <div>
                  <div>
                    <img src="https://www.jinyouapp.com/static/a/web/images/icon32.png" class="iconimg1" />
                  </div>
                  <div class="icontext">
                    思维导图
                  </div>
                </div>
                <div>
                  <div>
                    <img src="https://www.jinyouapp.com/static/a/web/images/icon32.png" class="iconimg1" />
                  </div>
                  <div class="icontext">
                    思维导图
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 20%;"></div>
        </div> -->
        <!-- <div class="mida1" style="background-color: #fff;display: flex;padding: 20px 10px;">
          <div style="width: 20%;"></div>
          <div style="margin: 20px 0px;width:60%;">
            <div class="centera1">
              <div class="centera2">
                <div class="centera3">
                  APP定制开发
                </div>
                <div style="font-size: 15px;color: #000;margin-top: 20px;">
                  山东金优网络科技有限公司，是一家专注于 互联网+ APP开发与推广的科技型公司。自成立以来，公司秉承帮助客户利用互联网和物联网解决行业难题、提升综合竞争力的宗旨，潜心技术研发，探寻各行业升级和转型模式
                </div>
                <div style="margin-top: 20px;">
                  <el-button plain>了解更多></el-button>
                </div>
              </div>
              <div class="imgaic">
                <img src="https://www.jinyouapp.com/static/a/web/images/pic4.png" class="img">
              </div>
            </div>
          </div>
          <div style="width: 20%;"></div>
        </div> -->
        <!-- <div class="mida1" style="background-color: rgb(52 53 55);display: flex;">
          <div style="width: 20%;"></div>
          <div style="width:60%;display: flex;justify-content: center;">
            <div>
              <div class="image-container" @mouseenter="hover = true" @mouseleave="hover = false"
                :style="backgroundStyle"></div>
            </div>
            <div>
              <div>
                <div class="image-container" @mouseenter="hover1 = true" @mouseleave="hover1 = false"
                  :style="backgroundStyle1"></div>
              </div>
            </div>
            <div>
              <div>
                <div class="image-container" @mouseenter="hover2 = true" @mouseleave="hover2 = false"
                  :style="backgroundStyle2"></div>
              </div>
            </div>
            <div>
              <div>
                <div class="image-container" @mouseenter="hover3 = true" @mouseleave="hover3 = false"
                  :style="backgroundStyle3"></div>
              </div>
            </div>
          </div>
          <div style="width: 20%;"></div>
        </div> -->
        <!-- <div class="mida1" style="background-color: #fff;display: flex;padding: 20px 10px;">
          <div style="width: 20%;"></div>
          <div style="margin: 20px 0px;width:60%;">
            <div class="centera1">
              <div class="imgaic">
                <img src="https://www.jinyouapp.com/static/a/web/images/pic5.jpg" class="img">
              </div>
              <div style="width: 40%;padding: 20px 0px 20px 60px;">
                <div class="centera3">
                  外卖系统
                </div>
                <div style="font-size: 18px;color: #409EFF;margin: 20px 0px;">
                  三个应用端+双管理后台，订单量提升50%
                </div>
                <div style="font-size: 15px;color: #000;margin-top: 20px;">
                  山东金优网络科技有限公司，是一家专注于 互联网+ APP开发与推广的科技型公司。自成立以来，公司秉承帮助客户利用互联网和物联网解决行业难题、提升综合竞争力的宗旨，潜心技术研发，探寻各行业升级和转型模式
                </div>
                <div style="margin-top: 20px;display: flex;">
                  <el-button plain>更多功能</el-button>
                  <el-button plain style="margin-left: 40px;">申请试用</el-button>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 20%;"></div>
        </div> -->
        <!-- <div class="mida1" style="background-color: #f3f4f6;display: flex;padding: 20px 10px;">
          <div style="width: 20%;"></div>
          <div style="margin: 20px 0px;width:60%;">
            <div style="display: flex;">

              <div style="width: 40%;padding: 20px 60px 20px 0px;">
                <div class="centera3">
                  外卖系统
                </div>
                <div style="font-size: 18px;color: #409EFF;margin: 20px 0px;">
                  三个应用端+双管理后台，订单量提升50%
                </div>
                <div style="font-size: 15px;color: #000;margin-top: 20px;">
                  山东金优网络科技有限公司，是一家专注于 互联网+ APP开发与推广的科技型公司。自成立以来，公司秉承帮助客户利用互联网和物联网解决行业难题、提升综合竞争力的宗旨，潜心技术研发，探寻各行业升级和转型模式
                </div>
                <div style="margin-top: 20px;display: flex;">
                  <el-button plain>更多功能</el-button>
                  <el-button plain style="margin-left: 40px;">申请试用</el-button>
                </div>
              </div>
              <div class="imgaic">
                <img src="https://www.jinyouapp.com/static/a/web/images/pic5.jpg" class="img">
              </div>
            </div>
          </div>
          <div style="width: 20%;"></div>
        </div> -->
        <!-- <div class="mida1" style="background-color: #fff;display: flex;padding: 20px 10px;">
          <div style="width: 20%;"></div>
          <div style="margin: 20px 0px;width:60%;">
            <div style="display: flex;justify-content: center;padding: 10px;">
              <div style="margin-right: 20px;">
                <div class="image-container" @mouseenter="hoverbo = true" @mouseleave="hoverbo = false"
                  :style="backgroundStylebo">
                  <div style="padding: 10px;position: relative;top: 270px;">
                    <div style="font-size: 20px;color: #fff;">
                      小程序
                    </div>
                    <div style="font-size: 15px;color: #fff;">
                      微信小程序针对低成本创业者，微信小程序针对低成本创业者，微信小程序针对低成本创业者，微信小程序针对低成本创业者，
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="image-container" @mouseenter="hoverbot = true" @mouseleave="hoverbot = false"
                  :style="backgroundStylebot">
                  <div style="padding: 10px;position: relative;top: 270px;">
                    <div style="font-size: 20px;color: #fff;">
                      小程序
                    </div>
                    <div style="font-size: 15px;color: #fff;">
                      微信小程序针对低成本创业者，微信小程序针对低成本创业者，微信小程序针对低成本创业者，微信小程序针对低成本创业者，
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 20%;"></div>
        </div>
        <div class="mida1" style="background-color: #fff;display: flex;padding: 20px 10px;">
          <div style="width: 20%;"></div>
          <div style="width:60%;">
            <div class="text1" style="font-size: 30px;font-weight: bold;color: #000;">
              6年技术积累助力行业领先
            </div>
            <div>
              <div style="display: flex;justify-content: space-around;align-items: center;margin-top: 20px;">
                <div style="padding: 30px;border-radius: 100px;background-color: #f3f4f6;">
                  <div style="font-size: 25px;font-weight: bold;color: #409EFF;">
                    6年
                  </div>
                  <div class="icontext">
                    从业时间
                  </div>
                </div>
                <div style="padding: 30px;border-radius: 100px;background-color: #f3f4f6;">
                  <div style="font-size: 25px;font-weight: bold;color: #409EFF;">
                    6年
                  </div>
                  <div class="icontext">
                    从业时间
                  </div>
                </div>
                <div style="padding: 30px;border-radius: 100px;background-color: #f3f4f6;">
                  <div style="font-size: 25px;font-weight: bold;color: #409EFF;">
                    6年
                  </div>
                  <div class="icontext">
                    从业时间
                  </div>
                </div>
                <div style="padding: 30px;border-radius: 100px;background-color: #f3f4f6;">
                  <div style="font-size: 25px;font-weight: bold;color: #409EFF;display: flex;justify-content: center;">
                    6年
                  </div>
                  <div class="icontext">
                    从业时间
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 20%;"></div>
        </div> -->

        <!-- <el-popover placement="bottom" width="150" trigger="hover">
          <div slot="reference" class="text1" style="font-size: 25px;color: #302f2f;margin-top: 25px;"
            @click="qrcode()">
            <img src="../image/iconerwm.png" style="width: 30px;height: 30px;">
            <div style="display: flex;align-items: center;margin-left: 5px;">
              下载众聚生活APP
            </div>
          </div>
          <div>
            <img src="../image/qr.jpg" style="width: 150px;height: 150px;">
          </div>
        </el-popover> -->

        <!-- <div class="dynamic">
          <div class="dynamic1">
            <div class="dynamic2">
              最新动态
            </div>
            <div class="dynamic3">
              <el-button plain @click="checkAll()">查看全部</el-button>
            </div>
          </div>

          <div class="grid-container">
            <div class="grid-item" v-for="(item, index) in cardList" :key="index" @click="goDetail(index)">
              <div>
                <div style="width: 100%;">
                  <img :src="item.cover_pic" class="imga">
                </div>
                <div style="padding: 10px;">
                  <div class="tit">
                    {{ item.title }}
                  </div>
                  <div class="label">
                    {{ item.key_desc }}
                  </div>
                  <div class="time">
                    {{ item.create_date }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="butall">
            <el-button plain @click="checkAll()">查看全部</el-button>
          </div>
        </div> -->
      </div>
    </div>
    <Bottom></Bottom>
  </div>
  <div v-else>
    <div style="background-color: #f3f4f6;">
      <div>
        <div style="padding: 10px;">
          <BottomIphone></BottomIphone>
        </div>
      </div>
    </div>
  </div>
  </div>
  
</template>
<script>
import Bottom from '@/components/bottom.vue'
import BottomIphone from "../components/bottomiphone.vue";
export default {
  components: {
    Bottom,
    BottomIphone
  },
  data () {
    return {
      ispc: false,

      activeIndex2: '1',
      activeIndex3: '0',
      titile: '众聚生活医药累计交易用户超三亿,携手药店药企构建15分钟医疗服务圈',
      label:
        '众聚生活持续推动服务零售和商品零售在需求侧和供给侧的数字化升级，和广大合作伙伴一起努力为消费者提供品质服务。2018年9月20日，众聚生活在港交所挂牌上市',
      time: '2024-05-10',
      cardList: [],
      isFixed: false,
      offsetTop: 0,

      isMobile: false,

      imgList: [{
        src: 'https://img.tukuppt.com/bg_grid/00/23/36/VoOjpBZbiU.jpg',
        // src: 'https://cdn.pixabay.com/photo/2020/08/02/14/18/blue-5457731_1280.jpg',
        text: ''
      }, {
        // src: 'https://cdn.pixabay.com/photo/2020/08/02/14/18/blue-5457731_1280.jpg',
        // src: 'https://img.tukuppt.com/bg_grid/00/23/36/VoOjpBZbiU.jpg',
        src: 'https://cdn.pixabay.com/photo/2017/07/10/23/45/cubes-2492010_1280.jpg',
        text: '强大的JAVA语言编程为客户提供最优最质的定制化需求解决方案'
      }, {
        src: 'https://cdn.pixabay.com/photo/2020/08/02/14/18/blue-5457731_1280.jpg',
        text: ''
      }],

      hover: false,
      backgroundImage: 'url(https://www.jinyouapp.com/static/a/web/images/pic1.jpg)', // 替换为你的背景图片 URL
      scale: 1,

      hover1: false,
      backgroundImage1: 'url(https://www.jinyouapp.com/static/a/web/images/pic2.jpg)', // 替换为你的背景图片 URL
      scale1: 1,

      hover2: false,
      backgroundImage2: 'url(https://www.jinyouapp.com/static/a/web/images/pic1.jpg)', // 替换为你的背景图片 URL
      scale2: 1,

      hover3: false,
      backgroundImage3: 'url(https://www.jinyouapp.com/static/a/web/images/pic1.jpg)', // 替换为你的背景图片 URL
      scale3: 1,

      hoverbo: false,
      backgroundImagebo: 'url(https://www.jinyouapp.com/static/a/web/images/pic7.jpg)', // 替换为你的背景图片 URL
      scalebo: 1,

      hoverbot: false,
      backgroundImagebot: 'url(https://www.jinyouapp.com/static/a/web/images/pic7.jpg)', // 替换为你的背景图片 URL
      scalebot: 1,

      gridItems: [
        {
          title: '外卖平台',
          src: 'https://www.hlnwl.com/webimages/appfw03.png'
        },
        {
          title: '校园外卖',
          src: 'https://www.hlnwl.com/webimages/appfw07.png'
        },
        {
          title: '商城',
          src: 'https://www.hlnwl.com/webimages/appfw02.png'
        },
        {
          title: '校园外卖',
          src: 'https://www.hlnwl.com/webimages/appfw10.png'
        },
        {
          title: '社区团购',
          src: 'https://www.hlnwl.com/webimages/appfw03.png'
        },
        {
          title: '团购',
          src: 'https://www.hlnwl.com/webimages/appfw04.png'
        },
        /* {
          title: '教育培训',
          src: 'https://www.hlnwl.com/webimages/appfw05.png'
        },
        {
          title: '教育培训',
          src: 'https://www.hlnwl.com/webimages/appfw06.png'
        },
        {
          title: '教育培训',
          src: 'https://www.hlnwl.com/webimages/appfw07.png'
        },
        {
          title: '教育培训',
          src: 'https://www.hlnwl.com/webimages/appfw08.png'
        },
        {
          title: '教育培训',
          src: 'https://www.hlnwl.com/webimages/appfw09.png'
        },
        {
          title: '教育培训',
          src: 'https://www.hlnwl.com/webimages/appfw10.png'
        }, */
        // {
        //   title: '教育培训',
        //   src: 'https://www.hlnwl.com/webimages/appfw11.png'
        // },
        // {
        //   title: '教育培训',
        //   src: 'https://www.hlnwl.com/webimages/appfw12.png'
        // }, {
        //   title: '教育培训',
        //   src: 'https://www.hlnwl.com/webimages/appfw13.png'
        // },
        // {
        //   title: '教育培训',
        //   src: 'https://www.hlnwl.com/webimages/appfw14.png'
        // },
        // {
        //   title: '教育培训',
        //   src: 'https://www.hlnwl.com/webimages/appfw15.png'
        // },
        // {
        //   title: '教育培训',
        //   src: 'https://www.hlnwl.com/webimages/appfw16.png'
        // },
        // {
        //   title: '教育培训',
        //   src: 'https://www.hlnwl.com/webimages/appfw17.png'
        // },
        // {
        //   title: '教育培训',
        //   src: 'https://www.hlnwl.com/webimages/appfw18.png'
        // }
      ],
      caseList: [{
        title: 'AI订单规划师',
        src: 'https://api.hlnwl.com/uploads/20240912/da8940f6f8e1c34bc0557d1c848190d5.png',
        label: 'AI订单规划师'
      }, {
        title: '外卖平台',
        src: 'https://api.hlnwl.com/uploads/20240912/da8940f6f8e1c34bc0557d1c848190d5.png',
        label: '外卖平台'
      }, {
        title: 'AI订单规划师',
        src: 'https://api.hlnwl.com/uploads/20240912/da8940f6f8e1c34bc0557d1c848190d5.png',
        label: 'AI订单规划师'
      }]
    }
  },
  computed: {
    backgroundStyle () {
      const scaleFactor = this.hover ? 1.2 : 1 // 放大 1.2 倍
      return {
        backgroundImage: this.backgroundImage,
        backgroundSize: `${scaleFactor * 100}% ${scaleFactor * 100}%`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '300px',
        height: '200px',
        transition: 'background-size 0.3s ease'
      }
    },
    backgroundStyle1 () {
      const scaleFactor = this.hover1 ? 1.2 : 1 // 放大 1.2 倍
      return {
        backgroundImage: this.backgroundImage1,
        backgroundSize: `${scaleFactor * 100}% ${scaleFactor * 100}%`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '300px',
        height: '200px',
        transition: 'background-size 0.3s ease'
      }
    },
    backgroundStyle2 () {
      const scaleFactor = this.hover2 ? 1.2 : 1 // 放大 1.2 倍
      return {
        backgroundImage: this.backgroundImage2,
        backgroundSize: `${scaleFactor * 100}% ${scaleFactor * 100}%`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '300px',
        height: '200px',
        transition: 'background-size 0.3s ease'
      }
    },
    backgroundStyle3 () {
      const scaleFactor = this.hover3 ? 1.2 : 1 // 放大 1.2 倍
      return {
        backgroundImage: this.backgroundImage3,
        backgroundSize: `${scaleFactor * 100}% ${scaleFactor * 100}%`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '300px',
        height: '200px',
        transition: 'background-size 0.3s ease'
      }
    },

    backgroundStylebo () {
      const scaleFactor = this.hoverbo ? 1.2 : 1 // 放大 1.2 倍
      return {
        backgroundImage: this.backgroundImagebo,
        backgroundSize: `${scaleFactor * 100}% ${scaleFactor * 100}%`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '500px',
        height: '400px',
        transition: 'background-size 0.3s ease'
      }
    },
    backgroundStylebot () {
      const scaleFactor = this.hoverbot ? 1.2 : 1 // 放大 1.2 倍
      return {
        backgroundImage: this.backgroundImagebot,
        backgroundSize: `${scaleFactor * 100}% ${scaleFactor * 100}%`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '500px',
        height: '400px',
        transition: 'background-size 0.3s ease'
      }
    }
  },
  mounted () {
    // this.loadScript();
    window.addEventListener('scroll', this.initHeight)
    this.$nextTick(() => {
      this.offsetTop = document.querySelector('#boxFixed').offsetTop
    })
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  created () {
    this.detectDeviceType()
    console.log('首页加载')
    this.articleList()
  },
  methods: {
    detectDeviceType() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // Regular expressions to match mobile devices
      const mobileRegex = /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;
      this.ispc = !mobileRegex.test(userAgent);
    },
    initHeight () {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.isFixed = scrollTop > this.offsetTop
    },
    handleSelect (key, keyPath) {
      console.log(key, keyPath)
      if (keyPath == 1) {
        this.$router.push('/')
      } else if (keyPath == 2) {
        this.$router.push('/cooperation')
      }
    },
    handleSelect1 (key, keyPath) {
      console.log(key, keyPath)
    },
    qrcode () {
      // 二维码
    },
    articleList () {
      this.axios({
        methods: 'get',
        url: '/article/getArticleList',
        // url: "http://192.168.0.196/kuyan/takeaway/public/index.php/article/getArticleList",
        params: {
          page: '1',
          page_size: '10'
        }
      }).then((response) => {
        // 处理成功情况
        console.log(response.data.data.list)
        // 你可以将response.data赋值给data中的某个属性，用于渲染
        this.cardList = response.data.data.list.slice(0, 9)
      })
        .catch((error) => {
          // 处理错误情况
          console.error('请求失败:', error)
        })
    },
    checkAll () {
      // this.$route.push('/articleList')
      this.$router.push({ path: '/articleList' })
    },
    goDetail (index) {
      // this.$router.push({ path: '/articleDetail' })
      this.$router.push({ path: '/articleDetail', query: { id: this.cardList[index].id } })
    }
  }
}
</script>
<style scoped>
.page {
  /* background-color: #f3f4f6; */
  /* background: linear-gradient(rgb(248, 244, 2), rgb(255, 254, 254)); */
  /* background-image: url("https://s3plus.meituan.net/smart/halfbg2.svg"); */
  /* background-image: url("https://s3plus.meituan.net/smart/halfbg2.svg"),
                  url('https://s3plus.meituan.net/smart/left-trans.svg'),
                  url('https://s3plus.meituan.net/smart/right2.svg');
background-repeat: no-repeat, no-repeat, no-repeat;
background-position: center top, left top, right top; */
  /* background-image: url("../image/bj.png"); */
  background-repeat: no-repeat;
  /*设置背景不重复*/
  background-attachment: fixed;
  /*背景图片不会随着页面的滚动而滚动。*/
}

.top {
  padding: 20px;
  /* background-color: #fff; */
  /* backdrop-filter: blur(20px); */
  display: flex;
  background-color: rgba(255, 255, 255, 0.6);
}

.is_fixed {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
}

.el-menu {
  background-color: transparent !important;
}

.el-menu.el-menu--horizontal {
  border: 0px !important;
}

.middle {
  display: flex;
  /* padding: 20px 0px; */
  /* background-color: #0aee43; */
}

/* .middle1 {
  display: flex;
  padding: 10px 0px;
  background-color: #eeea0a;
} */
.text1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dynamic {
  margin-top: 20px;
}

.dynamic1 {
  display: flex;
  margin-bottom: 20px;
}

.dynamic2 {
  font-size: 25px;
  font-weight: bold;
  color: #000;
}

.dynamic3 {
  position: absolute;
  right: calc(20% + 10px);
}

.tit {
  font-size: 20px;
  font-weight: bold;
  color: #000;
}

.label {
  font-size: 15px;
  color: #909399;
  margin: 10px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.time {
  font-size: 13px;
  color: #bfc2c7;
  margin-top: 20px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* 创建三列，每列宽度相等 */
  /* grid-template-rows: repeat(2, 1fr); 创建三行，每行高度相等 */
  gap: 10px;
  /* 格子之间的间隙 */
  /* height: 300px; 容器高度 */
  margin: 0 auto;
  /* 容器居中显示 */
  margin-top: 10px;
}

.grid-item {
  background-color: #fff;
  /* 格子背景色 */
  display: flex;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  font-size: 20px;
  /* 字体大小 */
  border-radius: 10px;
}

.imga {
  width: 100%;
  height: 200px;
  border-radius: 10px 10px 0px 0px;
}

.butall {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

/* 新增加样式 */
/* 轮播图 */
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.icontext {
  margin-top: 15px;
  font-size: 15px;
  color: #000;
  display: flex;
  justify-content: center;
}

.iconimg {
  border-radius: 100px;
  width: 100px;
  height: 100px
}

.iconimg1 {
  border-radius: 10px;
  width: 100px;
  height: 100px
}

.centera1 {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.centera2 {
  width: 40%;
  padding: 20px 40px 20px 0px;
}

.centera3 {
  font-size: 28px;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
  display: flex;
  margin-top: 20px;
}

.centera4 {
  font-size: 35px;
  font-weight: bold;
  color: #000;
  display: flex;
}

.centera5 {
  font-size: 15px;
  color: #909399;
  margin-top: 10px;
  line-height: 1.5;
}

.labela {
  margin-top: 30px;
}

.labela1 {
  display: flex;
}

.num {
  font-size: 40px;
  font-weight: bold;
  color: #fae102;
  margin: 0px 5px;
}

.numa1 {
  font-size: 15px;
  color: #909399;
  margin-top: 10px;
  display: flex;
}

.imgaic {
  width: 50%;
}

.img {
  width: 100%;
  height: 100%;
}

.cenaa {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  margin-top: 20px;
  width: 100%;
}

.cenaa1 {
  width: 50%;
  padding: 20px;
}

.cenaab {
  font-size: 35px;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
  display: flex;
  margin-top: 20px;
}

.labela {
  font-size: 35px;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
  display: flex;
  margin-top: 20px;
}

.intr {
  font-size: 15px;
  color: #909399;
  margin-top: 10px;
  line-height: 1.5;
}

.intt {
  margin-top: 30px;
}

.image-container {
  display: inline-block;
  /* 使容器根据内容大小调整 */
}

.imghuolie {
  width: 300px;
  height: 300px;
  /* position: absolute;
  right: 300px; */
  width: 25%;
  display: flex;
  float: right;
  padding: 20px;
  align-items: center;
}

.imghu {
  width: auto;
  height: 200px;
  /* margin-top: -60px; */
}

.centertexttit {
  width: 65%;
}

.centertext {
  font-size: 25px;
  color: #000;
  font-weight: bold;
}

/* 九宫格 */
.grid-containerd {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  /* grid-template-rows: repeat(3, 1fr);   */
  gap: 10px;
  width: 100%;
  /* max-width: 600px;   */
  margin: 0 auto;
}

.grid-itemd {
  background-color: #fff;
  border: 1px solid #909399;
  padding: 20px;
  text-align: center;
  font-size: 18px;
}

.griddiv {
  margin-top: 40px;
}

.casetext {
  font-size: 30px;
  font-weight: bold;
  color: #000;
  margin-bottom: 20px;
}

.casetit {
  font-size: 20px;
  color: #000;
  display: flex;
  margin: 10px 0px;
}

.caselab {
  font-size: 15px;
  color: #909399;
  display: flex;
}

.caseimg {
  width: 100%;
  height: 280px;
}

.grid-containerca {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-rows: repeat(3, 1fr);   */
  gap: 10px;
  width: 100%;
  /* max-width: 600px;   */
  margin: 0 auto;
}

.grid-itemca {
  /* background-color: #fff;   */
  /* border: 1px solid #909399;   */
  /* padding: 20px;   */
  text-align: center;
  font-size: 18px;
}

.morebut {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.tjdiv1 {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.tjdiv1tit {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 45px;
  color: #fff;
}

.tjdiv2tit {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 18px;
  color: #fff;
}

.tjdiv2 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 50px;
}

.newdiv {
  display: flex;
  padding: 10px;
}

.newdiv1 {
  width: calc(33% - 5px);
  margin-right: 10px;
}

.newdiv1tit {
  font-size: 20px;
  color: #000;
}

@media (max-width: 768px) {
  .middle {
    /* background-color: lightblue; */
    display: flex;
    padding: 10px 0px;
  }

  .imghuolie {
    width: 100px;
    height: 100px;
    /* position: absolute;
    right: 20px; */
    width: 30%;
    display: flex;
    float: right;
  }

  .griddiv {
    margin-top: 10px;
  }

  .grid-containerd {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-rows: repeat(3, 1fr);   */
    gap: 10px;
    width: 100%;
    /* max-width: 600px;   */
    margin: 0 auto;
  }

  .grid-itemd {
    background-color: #fff;
    border: 1px solid #909399;
    padding: 10px;
    text-align: center;
    font-size: 18px;
  }

  .casetext {
    font-size: 18px;
    font-weight: bold;
    color: #000;
  }

  .casetit {
    font-size: 15px;
    color: #000;
  }

  .caselab {
    font-size: 13px;
    color: #909399;
  }

  .caseimg {
    width: 100%;
    height: 100px;
  }

  .grid-containerca {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-rows: repeat(3, 1fr);   */
    gap: 10px;
    width: 100%;
    /* max-width: 600px;   */
    margin: 0 auto;
  }

  .grid-itemca {
    /* background-color: #fff;   */
    /* border: 1px solid #909399;   */
    /* padding: 20px;   */
    text-align: center;
    font-size: 15px;
  }

  .morebut {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }

  .tjdiv1tit {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 15px;
    color: #fff;
  }

  .tjdiv2tit {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 13px;
    color: #fff;
  }

  .tjdiv2 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
  }

  .newdiv1tit {
    font-size: 15px;
    color: #000;
  }
}
</style>
