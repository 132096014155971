<template>
  <div>
    <div style="background-color: #f3f4f6;display: flex;">
      <div style="width: 10%;"></div>
      <div class="from">
        <div class="fromtop">
          <div class="fromitem">
            <div class="fromimg">
              <img src="https://www.hlnwl.com/webimages/foot1.png">
            </div>
            <div class="fromtext">
              0元免费获取报价
            </div>
          </div>
          <div class="fromitem">
            <div class="fromimg">
              <img src="https://www.hlnwl.com/webimages/foot1.png">
            </div>
            <div class="fromtext">
              0元免费获取报价
            </div>
          </div>
          <div class="fromitem">
            <div class="fromimg">
              <img src="https://www.hlnwl.com/webimages/foot1.png">
            </div>
            <div class="fromtext">
              0元免费获取报价
            </div>
          </div>
        </div>
        <div class="frombot">
          <div class="fromleft">
            <div class="fromleftdiv">
              <div class="fromleftdivtit">
                多一份参考，总有益处
              </div>
              <div class="fromleftdivtit1">
                联系众聚生活，免费获得专属《策划方案》及报价！
              </div>
              <div class="fromleftdivtit2" style="display: flex;">
                <div style="font-size: 15px;">
                  留下您的信息
                </div>
                <div style="font-size: 15px;color: #fff;background-color: red;margin-left: 10px;">
                  获取项目报价
                </div>
              </div>
              <div class="fromleftdivfro">
                <el-form :rules="rules" ref="ruleform" :model="ruleform" label-width="80px" label-position="left">
                  <el-form-item label="姓名" prop="name">
                    <el-input v-model="ruleform.name" placeholder="请填写姓名"></el-input>
                  </el-form-item>
                  <el-form-item label="电话" prop="iphone">
                    <el-input v-model="ruleform.iphone" placeholder="请填写电话"></el-input>
                  </el-form-item>
                  <el-form-item label="微信" prop="wechat">
                    <el-input v-model="ruleform.wechat" placeholder="请填写微信"></el-input>
                  </el-form-item>
                  <el-form-item label="项目需求" prop="requ">
                    <el-input v-model="ruleform.requ" placeholder="请填写项目需求"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="consultation()">在线咨询</el-button>
                    <el-button @click="submitForm('ruleform')">提交需求</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
          <div class="fromright">
            <div>
              <div class="fromrightdivtit">
                联系我们
              </div>
              <div style="display: flex;">
                <div>
                  <img src="https://gwapi.kukuyan.cn/profile/upload/2024/10/15/footph_20241015093028A028.png">
                </div>
                <div class="rightiphon" style="margin-left: 10px;">
<!--                  7*24小时热线 13051999938-->
                  {{systemInfo.phone}}
                </div>
              </div>
              <div class="botrig" style="display: flex;">
                <div>
                  <img src="https://gwapi.kukuyan.cn/profile/upload/2024/10/15/footdz_20241015092902A027.png">
                </div>
                <div class="rightiphon" style="margin: 0px 10px;">
<!--                  北京市海淀区紫金大厦1011-->
                  {{systemInfo.address}}
                </div>
                <div>
                  <img :src="systemInfo.qrCodePath" class="rightimg" :alt="systemInfo.qrCodeName">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 10%;"></div>
    </div>
    <div class="botpage">
      <div style="width: 10%;"></div>
      <div class="botpage1">
        <div class="bottomdivtop">
          <div class="divtop">
            <div class="bottomtopit">
              <div class="bottomtoptit">
                关于众聚生活
              </div>
              <div class="bottomtopicon">
                +
              </div>
            </div>
            <div class="labelbot">
              众聚生活科技有限公司，结合营销、创意、内容等多维度，15年来为众多500强及上市企业提供互联网营销服务，服务领域包括APP开发，软件研发、系统平台开发、移动互联等。我们通过整体的互联网品牌分析，帮助企业建立品牌价值体系和品牌粘性，通过互联网提升企业的竞争力！
            </div>
          </div>
          <div class="divtop">
            <div class="bottomtopit">
              <div class="bottomtoptit">
                我们的优势
              </div>
              <div class="bottomtopicon">
                +
              </div>
            </div>
            <div style="display: flex;justify-content: space-around;align-items: center;">
              <div class="left">
                <div class="lilabel">
                  15年网站建设服务经验
                </div>
                <div class="lilabel">
                  服务27家世界500强企业
                </div>
                <div class="lilabel">
                  服务136家上市企业
                </div>
                <div class="lilabel">
                  服务行业龙头超过70家
                </div>
                <div class="lilabel">
                  多项互联网传播大奖
                </div>
              </div>
              <div class="right">
                <div class="lilabel">
                  互联网创意策划专家
                </div>
                <div class="lilabel">
                  经验丰富的软件开发团队
                </div>
                <div class="lilabel">
                  完备的项目流程管理体系
                </div>
                <div class="lilabel">
                  B2C营销型网站建设供应商
                </div>
                <div class="lilabel">
                  系统研发与互联网技术并重
                </div>
              </div>
            </div>

          </div>
          <div class="divtop">
            <div class="bottomtopit">
              <div class="bottomtoptit">
                关于众聚生活
              </div>
              <div class="bottomtopicon">
                +
              </div>
            </div>
            <div class="labelbot">

              众聚生活的互联网解决方案结合了十五年的互联网品牌设计经验和整合营销的理念，并将软件开发技术和传播技术紧密结合，我们不只是关注设计视觉个性的建立，我们还通过创意策划使得我们的设计让软件原本零碎分布的片段更好的整合起来，众聚生活的“创意+整合+营销”帮助客户插上互联网的翅膀！
            </div>
          </div>

        </div>
      </div>
      <div style="width: 10%;">
      </div>
    </div>
    <div class="bota1">
      <div class="botla">
        公司名称：{{systemInfo.companyName}}; &nbsp;&nbsp;&nbsp; 公司地址:{{systemInfo.address}}; &nbsp;&nbsp;&nbsp; 公司电话：{{systemInfo.phone}}
      </div>
      <div style="display: flex;justify-content: center;align-items: center;font-size: 13px;color: #909399;">
        <div>
          ©2024 众聚生活版权所有
        </div>
        <el-link type="info">京ICP备10211739号</el-link>
        <el-link type="info">京公网安备 11000002002052号</el-link>
        <el-link type="info">隐私政策</el-link>
        <el-link type="info">规则中心</el-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Bottom',
  data () {
    return {

      systemInfo: {},

      ruleform: {
        name: '',
        iphone: '',
        wechat: '',
        requ: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' }
        ],
        iphone: [
          // { required: true,message: '请填写手机号', trigger: 'blur' ,type: 'number',length:11 }
          { required: true, message: '请填写手机号', trigger: 'blur' }
        ],
        wechat: [
          { required: true, message: '请填写微信号', trigger: 'blur' }
        ],
        requ: [
          { required: true, message: '请填写项目需求', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
    this.systemInformation()
  },
  methods: {
    consultation () {
      console.log('在线咨询aaaa')
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.axios.post('/sitemanager/caseplan', {
            name: this.ruleform.name,
            phone: this.ruleform.iphone,
            wechat: this.ruleform.wechat,
            demand: this.ruleform.requ
          })
            .then((response) => {
              if (response.data.code === 200) {
                this.$message({
                  message: '提交成功',
                  type: 'success'
                })
                this.resetForm(formName)
              } else {
                this.$message.error('提交失败')
              }
            })
            .catch((error) => {
              console.log(error)
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    // 系统信息
    systemInformation () {
      this.axios({
        methods: 'get',
        url: '/sitemanager/systeminfo/info',
        params: {}
      })
        .then((response) => {
          this.systemInfo = response.data.data;
        })
        .catch((error) => {
          // 处理错误情况
          console.error('请求失败:', error)
        })
    }

  }
}
</script>
<style scoped>
.botpage {
  display: flex;
  background-color: #333;
  padding: 20px 0px;
  border-bottom: 1px solid #4d4d4d;
}

.botpage1 {
  width: 80%;
  display: flex;
}

.botpage2 {
  width: 40%;
  display: flex;
}

.bota1 {
  padding: 30px;
  background-color: #333;
}

.botla {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: #909399;
  margin-bottom: 10px;
}

.bottomdivtop {
  display: flex;
  justify-content: space-around;
}

.divtop {
  width: calc(33% - 60px);
}

.bottomtopit {
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #4d4d4d;
}

.bottomtoptit {
  font-size: 25px;
  color: #ccc;
  width: 50%;
}

.bottomtopicon {
  width: 50%;
  text-align: right;
  font-size: 25px;
  color: #909399;
}

.labelbot {
  font-size: 14px;
  color: #666;
  padding: 20px 0px;
  line-height: 26px;
}

.lilabel {
  font-size: 14px;
  color: #666;
  line-height: 26px;
}

.left {
  width: 50%;
  padding: 10px 0px;
}

.right {
  width: 50%;
  padding: 10px 0px;
}

.from {
  width: 80%;
}

.fromtop {
  padding: 20px;
  border-bottom: solid 1px #909399;
  display: flex;
  justify-content: space-around;
}

.fromitem {
  padding: 10px;
}

.fromimg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fromtext {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.frombot {
  display: flex;
}

.fromleft {
  width: calc(50% - 40px);
  padding: 10px 20px;
}

.fromleftdiv {
  padding: 10px;
}

.fromright {
  width: calc(50% - 40px);
  padding: 10px 20px;
}

.fromleftdivtit {
  font-size: 40px;
  color: #fc0505;
  font-weight: bold;
}

.fromleftdivtit1 {
  font-size: 20px;
  color: #000;
  font-weight: bold;
  margin: 10px 0px;
}

.fromleftdivfro {
  padding: 20px 0px;
}

.fromrightdivtit {
  font-size: 20px;
  font-weight: bold;
  color: #000;
  margin-bottom: 20px;
}

.rightiphon {
  font-size: 18px;
}

.botrig {
  margin-top: 20px;
}

.rightimg {
  width: 200px;
  height: 200px;
}

@media (max-width: 768px) {
  .bottomtopit {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid #4d4d4d;
  }

  .bottomtoptit {
    font-size: 15px;
    color: #ccc;
    width: 50%;
  }

  .bottomtopicon {
    width: 50%;
    text-align: right;
    font-size: 15px;
    color: #909399;
  }

  .labelbot {
    font-size: 10px;
    color: #666;
    padding: 10px 0px;
    line-height: 26px;
  }

  .from {
    width: 80%;
  }

  .fromtop {
    padding: 10px;
    border-bottom: solid 1px #909399;
    display: flex;
    justify-content: space-around;
  }

  .fromitem {
    padding: 10px;
  }

  .fromimg {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fromtext {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
  }

  .frombot {
    display: flex;
  }

  .fromleft {
    width: calc(50% - 40px);
    padding: 10px 20px;
  }

  .fromleftdiv {
    padding: 10px;
  }

  .fromright {
    width: calc(50% - 20px);
    padding: 10px;
  }

  .fromleftdivtit {
    font-size: 20px;
    color: #fc0505;
    font-weight: bold;
  }

  .fromleftdivtit1 {
    font-size: 10px;
    color: #000;
    font-weight: bold;
    margin: 10px 0px;
  }

  .fromleftdivfro {
    padding: 10px 0px;
  }

  .fromrightdivtit {
    font-size: 15px;
    font-weight: bold;
    color: #000;
    margin-bottom: 10px;
  }

  .rightiphon {
    font-size: 13px;
  }

  .botrig {
    margin-top: 10px;
  }

  .rightimg {
    width: 100px;
    height: 100px;
  }
}
</style>
