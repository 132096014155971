<template>
  <div>
    <div class="botpage">
      <div>
        <el-collapse v-model="activeNames" @change="handleChange" accordion>
          <el-collapse-item title="下载和关注" name="1">
            <!-- <div>社会招聘</div>
            <div>校园招聘</div> -->
            <div style="background-color: #f3f4f6;">
              <el-popover placement="right" width="150" trigger="hover">
              <div slot="reference" class="refe">
                <img src="../image/zjgw.png" class="refeimg">
                <el-link :underline="false" type="info">众聚生活App</el-link>
              </div>
              <div>
                <img src="../image/qr.jpg" class="imgqr">
              </div>
            </el-popover>
            </div>
            <div style="background-color: #f3f4f6;">
              <el-popover placement="right" width="150" trigger="hover">
              <div slot="reference" class="refa">
                <img src="../image/zjgw.png" class="refeimg">
                <el-link :underline="false" type="info">众聚生活公众号</el-link>
              </div>
              <div>
                <img src="../image/qr.jpg" class="imgqr">
              </div>
            </el-popover>
            </div>
            <div style="background-color: #f3f4f6;">
              <el-popover placement="right" width="150" trigger="hover">
              <div slot="reference" class="refe">
                <img src="../image/zjgw.png" class="refeimg">
                <el-link :underline="false" type="info">众聚生活微博</el-link>
              </div>
              <div>
                <img src="../image/qr.jpg" class="imgqr">
              </div>
            </el-popover>
            </div>
            <div style="background-color: #f3f4f6;">
              <el-popover placement="right" width="150" trigger="hover">
              <div slot="reference" class="refa">
                <img src="../image/zjgw.png" class="refeimg">
                <el-link :underline="false" type="info">众聚生活微信公众号</el-link>
              </div>
              <div>
                <img src="../image/qr.jpg" class="imgqr">
              </div>
            </el-popover>
            </div>
          </el-collapse-item>
          <el-collapse-item title="加入我们" name="2">
            <div class="label1" style="background-color: #f3f4f6;">
              <el-popover placement="right" width="150" trigger="hover">
                <div slot="reference" class="refe">
                  <el-link>社会招聘</el-link>
                </div>
                <div>
                  <img src="../image/zjgw.png" class="imgqr">
                </div>
              </el-popover>
            </div>
            <div class="label2" style="background-color: #f3f4f6;">
              <el-popover placement="right" width="150" trigger="hover">
                <div slot="reference" class="refe">
                  <el-link>校园招聘</el-link>
                </div>
                <div>
                  <img src="../image/zjgw.png" class="imgqr">
                </div>
              </el-popover>
            </div>
            <div class="label2" style="background-color: #f3f4f6;">
              <el-popover placement="right" width="150" trigger="hover">
                <div slot="reference" class="refe">
                  <el-link>工作兼职</el-link>
                </div>
                <div>
                  <img src="../image/zjgw.png" class="imgqr">
                </div>
              </el-popover>
            </div>
          </el-collapse-item>
          <el-collapse-item title="联系我们" name="3">
            <!-- <div>服务热线</div>
            <div>清晰明确：语言表达清晰且表意明确，让用户快速理解进而作出决策；</div>
            <div>帮助用户识别：界面简单直白，让用户快速识别而非回忆，减少用户记忆负担。</div> -->
            <div class="label1" style="background-color: #f3f4f6;">
              <el-popover placement="right" width="150" trigger="hover">
                <div slot="reference" class="refe">
                  <el-link>服务热线</el-link>
                </div>
                <div>
                  <img src="../image/zjgw.png" class="imgqr">
                </div>
              </el-popover>
            </div>
            <div class="label2" style="background-color: #f3f4f6;">
              <el-popover placement="right" width="150" trigger="hover">
                <div slot="reference" class="refe">
                  <el-link>廉政举报</el-link>
                </div>
                <div>
                  <img src="../image/zjgw.png" class="imgqr">
                </div>
              </el-popover>
            </div>
            <div class="label2" style="background-color: #f3f4f6;">
              <el-popover placement="right" width="150" trigger="hover">
                <div slot="reference" class="refe">
                  <el-link>知识产权</el-link>
                </div>
                <div>
                  <img src="../image/zjgw.png" class="imgqr">
                </div>
              </el-popover>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>

      <!-- <div class="botpage1">

        <div class="labelcenter">
          <div style="width: 20%;">
            <div class="label3">
              加入我们
            </div>
            <div class="label1">
              <el-popover placement="left" width="150" trigger="hover">
                <div slot="reference" class="refe">
                  <el-link>社会招聘</el-link>
                </div>
                <div>
                  <img src="../image/zjgw.png" class="imgqr">
                </div>
              </el-popover>
            </div>
            <div class="label2">
              <el-popover placement="left" width="150" trigger="hover">
                <div slot="reference" class="refe">
                  <el-link>校园招聘</el-link>
                </div>
                <div>
                  <img src="../image/zjgw.png" class="imgqr">
                </div>
              </el-popover>
            </div>
            <div class="label2">
              <el-popover placement="left" width="150" trigger="hover">
                <div slot="reference" class="refe">
                  <el-link>工作兼职</el-link>
                </div>
                <div>
                  <img src="../image/zjgw.png" class="imgqr">
                </div>
              </el-popover>
            </div>
          </div>
          <div style="width: 20%;">
            <div class="label3">
              联系我们
            </div>
            <div class="label1">
              <el-popover placement="left" width="150" trigger="hover">
                <div slot="reference" class="refe">
                  <el-link>服务热线</el-link>
                </div>
                <div>
                  <img src="../image/zjgw.png" class="imgqr">
                </div>
              </el-popover>
            </div>
            <div class="label2">
              <el-popover placement="left" width="150" trigger="hover">
                <div slot="reference" class="refe">
                  <el-link>廉政举报</el-link>
                </div>
                <div>
                  <img src="../image/zjgw.png" class="imgqr">
                </div>
              </el-popover>
            </div>
            <div class="label2">
              <el-popover placement="left" width="150" trigger="hover">
                <div slot="reference" class="refe">
                  <el-link>知识产权</el-link>
                </div>
                <div>
                  <img src="../image/zjgw.png" class="imgqr">
                </div>
              </el-popover>
            </div>
          </div>
          <div class="laa">
            <div class="laa1">
              下载和关注
            </div>
            <el-popover placement="left" width="150" trigger="hover">
              <div slot="reference" class="refe">
                <img src="../image/zjgw.png" class="refeimg">
                <el-link :underline="false" type="info">众聚生活App</el-link>
              </div>
              <div>
                <img src="../image/qr.jpg" class="imgqr">
              </div>
            </el-popover>
            <el-popover placement="left" width="150" trigger="hover">
              <div slot="reference" class="refa">
                <img src="../image/zjgw.png" class="refeimg">
                <el-link :underline="false" type="info">众聚生活公众号</el-link>
              </div>
              <div>
                <img src="../image/qr.jpg" class="imgqr">
              </div>
            </el-popover>
          </div>
          <div class="ica">
            <div class="ica1">

            </div>
            <el-popover placement="right" width="150" trigger="hover">
              <div slot="reference" class="refe">
                <img src="../image/zjgw.png" class="refeimg">
                <el-link :underline="false" type="info">众聚生活微博</el-link>
              </div>
              <div>
                <img src="../image/qr.jpg" class="imgqr">
              </div>
            </el-popover>
            <el-popover placement="right" width="150" trigger="hover">
              <div slot="reference" class="refa">
                <img src="../image/zjgw.png" class="refeimg">
                <el-link :underline="false" type="info">众聚生活微信公众号</el-link>
              </div>
              <div>
                <img src="../image/qr.jpg" class="imgqr">
              </div>
            </el-popover>
          </div>
        </div>
      </div> -->
    </div>
    <div class="bota1">
      <div style="display: flex;justify-content: center;">
        <div style="margin-right: 10px;">
          <img src="../image/zjgw.png" style="width: 25px;height: 25px;border-radius: 5px;">
        </div>
        <div style="font-size: 20px;font-weight: bold;">
          众聚生活
        </div>
      </div>
      <div class="botla">
        众聚生活简介：众聚生活是北京酷演科技有限公司开发的一款外卖平台；公司住址：北京市海淀区中关村南大街乙12号院1住号楼14层1705；公司联系电话：13051999938
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BottomIphone",
  data() {
    return {
      activeNames: ['0']
    }

  },
  methods: {
    handleChange(val) {
      console.log(val);
    }
  }
}
</script>
<style scoped>
.botpage {
  /* display: flex; */
  background-color: #f3f4f6;
  padding: 20px 0px;
  /* border-bottom: 1px solid #dcdddf; */
}

.botpage1 {
  width: 60%;
  display: flex;
}

.botpage2 {
  width: 40%;
  display: flex;
}

.img {
  border-radius: 10px;
  width: 40px;
  height: 40px;
}

.tit {
  margin-left: 10px;
  font-size: 30px;
  font-weight: bold;
  color: #000;
  margin-top: -3px;
}

.labelcenter {
  width: 60%;
  display: flex;
}

.labelcenter1 {
  width: 20%;
}

.labeltit {
  font-size: 15px;
  color: #000;
  margin-bottom: 10px;
}

.label1 {
  font-size: 14px;
  color: #909399;
  margin-bottom: 10px;
  padding-top: 10px;
}

.label2 {
  font-size: 14px;
  color: #909399;
  margin-bottom: 10px;
}

.label3 {
  font-size: 15px;
  color: #000;
  /* margin-bottom: 10px; */
}

.laa {
  width: 25%;
  padding-left: 20px;
}

.laa1 {
  font-size: 15px;
  color: #000;
  margin-bottom: 10px;
  display: flex;
}

.refe {
  font-size: 14px;
  color: #909399;
  margin-bottom: 10px;
  display: flex;
  padding-top: 10px;
}

.refeimg {
  border-radius: 5px;
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.imgqr {
  width: 150px;
  height: 150px;
}

.refa {
  font-size: 14px;
  color: #909399;
  margin-bottom: 10px;
  display: flex;
}

.ica {
  width: 25%;
  padding-left: 20px;
}

.ica1 {
  font-size: 15px;
  color: #000;
  margin-bottom: 10px;
  height: 20px;
}

.bota1 {
  padding: 10px;
  background-color: #f3f4f6;
}

.botla {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #909399;
  margin: 10px 0px;
  line-height: 26px;
}

/* .el-collapse-item__header{
  background-color: #f3f4f6 !important;
}
.el-collapse-item__header.is-active {
  background-color: #f3f4f6 !important;
}
.el-collapse-item__wrap{
  background-color: #f3f4f6 !important;
} */

/deep/.el-collapse {
      background-color: #f3f4f6 !important;
    }
    /deep/.el-collapse,.el-collapse-item__wrap {
      border: none;
      background-color: #f3f4f6 !important;
    }
    /deep/ .el-collapse-item__header {
        /* color: #fff; */
        background-color: #f3f4f6 !important;
    }
    /deep/ .el-collapse-item__content {
        /* color: #fff; */
        background-color: #f3f4f6 !important;
    }
</style>
