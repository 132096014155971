<template>
  <div id="app">
    <!-- <Header></Header> -->
    <div class="top" v-if="ispc == true">
    <!-- <div class="top" :class="{ is_fixed: isFixed }" v-if="ispc == true"> -->
      <div style="display: flex;background-color: rgba(255, 255, 255, 0);padding: 20px;position: absolute;top: 0px; z-index: 999;width: calc(100% - 40px);">
        <div style="display: flex; width: 20%">
          <div>
            <img :src="systemInfo.logoPath" style="border-radius: 10px; width: 50px; height: 50px" :alt="systemInfo.logoName" />
          </div>
          <div style="
            margin-left: 10px;
            font-size: 30px;
            font-weight: bold;
            color: #fff;
          ">
            {{systemInfo.name}}
          </div>
        </div>
        <div style="width: 60%; display: flex; justify-content: center">
          <el-menu :default-active="$route.path" :router="true" class="el-menu-demo" mode="horizontal" background-color="rgba(255, 255, 255, 0)"
            active-text-color="#ffd04b">
            <el-menu-item index="/">
              <div style="color: #fff;">
                首页
              </div>
              </el-menu-item>
            <el-menu-item index="/cooperation">
              <div style="color: #fff;">
                App开发
              </div>
              </el-menu-item>
            <el-menu-item index="/science">
              <div style="color: #fff;">
                系统开发
              </div>
              </el-menu-item>
            <el-menu-item index="/web">
              <div style="color: #fff;">
                网站建设
              </div>
              </el-menu-item>
            <el-menu-item index="/option">
              <div style="color: #fff;">
                方案
              </div>
              </el-menu-item>
            <el-menu-item index="/case">
              <div style="color: #fff;">
                案例中心
              </div>
              </el-menu-item>
            <el-menu-item index="/sign">
              <div style="color: #fff;">
                签约资讯
              </div>
              </el-menu-item>
            <el-menu-item index="/joinmy">
              <div style="color: #fff;">
                了解我们
              </div>
              </el-menu-item>
          </el-menu>
        </div>
      </div>
      <!-- <div>
        <div class="block">
          <el-carousel height="400px">
            <el-carousel-item v-for="(item, index) in imgList" :key="index">
              <img :src="item.src" style="width: 100%;">
            </el-carousel-item>
          </el-carousel>
        </div>
      </div> -->

    </div>
    <div class="andio" v-if="isandio == true">
      <div class="andiotop">
        <div style="display: flex">
          <div style="margin-right: 5px;">
            <img src="https://gwapi.kukuyan.cn/profile/upload/2024/10/14/zjgw.6046584a_20241014172853A020.png" style="border-radius: 10px; width: 30px; height: 30px" />
          </div>
          <div style="font-size: 18px;font-weight: bold;display: flex;align-items: center;">众聚生活</div>
        </div>
      </div>
      <div>
        <div style="position: absolute;top: 20px;left: 20px;">
          <i class="el-icon-s-operation" style="color: white;font-size: 25px;" @click="drawer = true"></i>
        </div>
        <el-drawer :visible.sync="drawer" :direction="direction" size="200px" :show-close="false">
          <div style="padding: 0px 0px 0px 10px;">
            <div style="display: flex;">
              <div style="margin-right: 5px;">
                <img src="https://gwapi.kukuyan.cn/profile/upload/2024/10/14/zjgw.6046584a_20241014172853A020.png" style="border-radius: 10px; width: 30px; height: 30px" />
              </div>
              <div style="font-size: 18px;font-weight: bold;display: flex;align-items: center;">众聚生活</div>
            </div>
            <div style="width: 100%;">
              <el-row class="tac">
                <el-col :span="12">
                  <el-menu :default-active="$route.path" :router="true" class="el-menu-vertical-demo"
                    @select="closepage()" @open="handleOpentab" @close="handleClosetab">
                    <el-menu-item index="/">
                      <span slot="title">首页</span>
                    </el-menu-item>
                    <el-menu-item index="/cooperation">
                      <span slot="title">App开发</span>
                    </el-menu-item>
                    <el-menu-item index="/science">
                      <span slot="title">系统开发</span>
                    </el-menu-item>
                    <el-menu-item index="/web">
                      <span slot="title">网站建设</span>
                    </el-menu-item>
                    <el-menu-item index="/option">
                      <span slot="title">方案</span>
                    </el-menu-item>
                    <el-menu-item index="/case">
                      <span slot="title">案例中心</span>
                    </el-menu-item>
                    <el-menu-item index="/sign">
                      <span slot="title">签约资讯</span>
                    </el-menu-item>
                    <el-menu-item index="/joinmy">
                      <span slot="title">了解我们</span>
                    </el-menu-item>
                  </el-menu>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-drawer>
      </div>
    </div>
    <router-view />
  </div>
</template>
<script>
// import Header from '../components/top.vue'
export default {
  // components: {
  //   Header
  // },
  data() {
    return {

      systemInfo: {},

      isFixed: false,
      offsetTop: 0,
      ispc: false,
      isandio: false,

      drawer: false,
      direction: 'ltr',
      imgList: [{
        src: 'http://new.jinyouapp.com/uploadfile/202205/3bed747485518be.jpg',
      }, {
        src: 'http://new.jinyouapp.com/uploadfile/202205/c3827eba2e84300.jpg',
      }, {
        src: '../image/img3.png',
      },],
    };
  },
  mounted() {
    if (this.isMobile()) {
      console.log('app.vue')
      this.isandio = true;
      // this.$router.replace('/m_index');
    } else {
      this.ispc = true;
      // this.$router.replace('/pc_index');
      window.addEventListener("scroll", this.initHeight);
      this.$nextTick(() => {
        this.offsetTop = document.querySelector("#boxFixed").offsetTop;
      });
    }

    this.systemInformation();

  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    closepage() {
      this.drawer = false
    },
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    initHeight() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.isFixed = scrollTop > this.offsetTop ? true : false;
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      // this.$router.push(key);
      if (this.$route.path !== key) {
        this.$router.push(key);
      }
      // this.$router.push(key).catch(err => {
      //     if (err.name !== 'NavigationDuplicated') {
      //       // 处理其他类型的错误
      //       throw err;
      //     }
      //     // 对于重复的导航，这里可以什么也不做
      //   });
      // if (keyPath == 1) {
      //   if (this.$route.path !== "/") {
      //     this.$router.push("/");
      //   }
      //   // this.$router.push('/')
      //   //         this.$router.push('/').catch(err => {
      //   //   if (err.name !== 'NavigationDuplicated') {
      //   //     // 处理其他类型的错误
      //   //     throw err;
      //   //   }
      //   //   // 对于重复的导航，这里可以什么也不做
      //   // });
      // } else if (keyPath == 2) {
      //   if (this.$route.path !== "/cooperation") {
      //     this.$router.push("/cooperation");
      //   }
      //   // this.$router.push('/cooperation')
      //   // this.$router.push("/cooperation").catch((err) => {
      //   //   if (err.name !== "NavigationDuplicated") {
      //   //     // 处理其他类型的错误
      //   //     throw err;
      //   //   }
      //   //   // 对于重复的导航，这里可以什么也不做
      //   // });
      // }
    },
    handleOpentab(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClosetab(key, keyPath) {
      console.log(key, keyPath);
    },

    // 系统信息
    systemInformation () {
      this.axios({
        methods: 'get',
        url: '/sitemanager/systeminfo/info',
        params: {}
      })
        .then((response) => {
          this.systemInfo = response.data.data;
        })
        .catch((error) => {
          // 处理错误情况
          console.error('请求失败:', error)
        })
    }

  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}

body {
  margin: 0 !important;
}

.el-dropdown-menu__item,
.el-menu-item {
  font-size: 15px !important;
  padding: 0px 35px !important;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.top {
  /* padding: 20px; */
  /* background-color: #fff; */
  /* backdrop-filter: blur(20px); */
  /* display: flex; */
  /* background-color: rgba(255, 255, 255, 0.6); */
  /* position: absolute;
  top: 0px;
  z-index: 999;
  width: 100%; */
}

.is_fixed {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
}

.el-menu {
  background-color: transparent !important;
}

.el-menu.el-menu--horizontal {
  border: 0px !important;
}

.andiotop {
  height: 60px;
  background-color: #ffc583;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-col-12 {
  width: 100% !important;
}
/* 轮播图 */
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
/* 导航栏鼠标移入颜色改变 */
.el-menu-item:hover {
background-color: rgba(255, 255, 255, 0) !important;
}
.el-menu--horizontal>.el-menu-item.is-active{
  border-bottom-width: 3px !important;
}
</style>
